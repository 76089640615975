import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  children?: string;
}

export const AppCheckbox: React.FC<Props> = ({
  checked,
  className,
  labelClassName,
  containerClassName,
  children,
  ...props
}) => {
  // const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     setIsChecked(!isChecked);
  //   }
  // };

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <input
        type="checkbox"
        id="checkbox"
        // onKeyDown={handleEnterKey}
        className={classNames(
          styles.checkbox,
          { [styles.checked]: checked },
          className
        )}
        {...props}
      />
      <label
        htmlFor="checkbox"
        className={classNames(styles.label, labelClassName)}
      >
        {children}
      </label>
    </div>
  );
};

import axios from "axios";
import { getAccessToken } from "../utils";
import { APP_ACCESS_TOKEN_NAME, APP_REFRESH_TOKEN_NAME } from "../constants";

let APP_KEYCLOAK_API_URL = "/";
let APP_KEYCLOAK_REALM = "";
export let APP_KEYCLOAK_CLIENT_ID = "";

if (process.env.REACT_APP_ENV_SOURCE === "dev") {
	APP_KEYCLOAK_API_URL = process.env.REACT_APP_KEY_CLOACK_URL || "/";
	APP_KEYCLOAK_REALM = process.env.REACT_APP_KEY_CLOACK_REALM_NAME || "";
	APP_KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEY_CLOACK_CLIENT_ID || "";
} else {
	// @ts-ignore
	APP_KEYCLOAK_API_URL = window.__RUNTIME_CONFIG__?.REACT_APP_KEY_CLOACK_URL;
	APP_KEYCLOAK_REALM =
		// @ts-ignore
		window.__RUNTIME_CONFIG__?.REACT_APP_KEY_CLOACK_REALM_NAME || "";
	APP_KEYCLOAK_CLIENT_ID =
		// @ts-ignore
		window.__RUNTIME_CONFIG__?.REACT_APP_KEY_CLOACK_CLIENT_ID || "";
}

export let appKeycloakApi = axios.create({
	baseURL: `${APP_KEYCLOAK_API_URL}/realms/${APP_KEYCLOAK_REALM}/protocol/openid-connect`,
});

export const recreateAppKeycloakApi = (setUserInfo: (value: null) => void) => {
	appKeycloakApi = axios.create({
		baseURL: `${APP_KEYCLOAK_API_URL}/realms/${APP_KEYCLOAK_REALM}/protocol/openid-connect`,
	});

	appKeycloakApi.interceptors.request.use((request) => {
		if (request.headers) {
			request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
		}
		return request;
	});

	appKeycloakApi.interceptors.response.use(
		(response) => {
			return response;
		},
		function (error) {
			if (error.response.status === 401) {
				localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
				localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
				setUserInfo(null);
			}
			return Promise.reject(error);
		}
	);
};

import { useEffect, useRef, useState } from "react";
import { AppSidebar } from "../../shared/components/AppSidebar";
import { AssistMain } from "../../common/Assistant/AssistMain";
import { AssistChat } from "../../common/Assistant/AssistChat";
import {
	addAssistantFunction,
	createAssistant,
	createRun,
	createThread,
	deleteAssistant,
	getAssistantFunctions,
	getAssistantFunctionSchema,
	getFileContent,
	getMessages,
	getRunStatus,
	getStores,
	postCompMessage,
	runAssistantFunction,
	submitAssistantToolOutputs,
	updateAssistant,
	uploadFile,
} from "../../services/api/methods";
import {
	AssistantFunctionNames,
	AssistantFunctionRunOutput,
	AssistantFunctionRunPostObjType,
	AssistantFunctionToolOutput,
	AssistantInfo,
	AssistantMessageRoles,
	AssistantNewFunctionType,
	AssistantToolsObject,
	CompMessageType,
	FilePurpose,
	ModelsArray,
	RunInfo,
	StoreData,
} from "../../services/api/methodsTypes";
import {
	AppDeleteDialog,
	defaultDeleteInfo,
} from "../../shared/components/AppDialog/AppDeleteDialog";
import { getAssistantModeMessages } from "../../shared/utils/chatFunctions";
import {
	updateAssistantList,
	createInitialThreadList,
	addThreadToThreadList,
	createThreadLink,
	sortAssistantData,
	getFunctionsWithEnabled,
	getFilteredAssistantToPost,
} from "../../shared/utils/assistantPageFunctions";
import { showError } from "../../shared/utils/showError/showError";
import { useProfile } from "../../services/profile";
import { ResizableWrapper } from "../../shared/components/ResizableWrapper";
import styles from "./styles.module.css";
import { getTransformedModels } from "../../shared/utils/getTransformedModels";
import { AppNewFunctionDialog } from "../../shared/components/AppDialog/AppNewFunctionDialog";

export type ThreadInfo = {
	assistantId: string;
	threadId: string;
	messages: LocalAssistantMessage[];
};

export type LocalAssistantMessage = {
	id?: string;
	role: AssistantMessageRoles;
	content: string;
	fileName?: string | null;
	isJson?: boolean;
};

export type AssistantFunctionNamesWithEnabled = AssistantFunctionNames & {
	enabled: boolean;
};

export type LocalAssistantInfo = AssistantInfo & {
	localAvailableFunctions?: AssistantFunctionNamesWithEnabled[];
	localFunctionInfo?: AssistantToolsObject[];
	localFileSearchId?: string;
	localResponseFormat?: string;
	localJustSwitchedToJson?: boolean;
};

export type StoreDataWithPlaceholder = {
	id: StoreData["id"];
	name: StoreData["name"];
} & Partial<Omit<StoreData, "id" | "name">>;

export type AssistAttachedFileType = {
	id: string;
	name: string;
};

export const AssistantPage = () => {
	const [assistantList, setAssistantList] = useState<
		LocalAssistantInfo[] | null
	>(null);
	const [selectedAssistantId, setSelectedAssistantId] = useState<string | null>(
		null
	);
	const [threadList, setThreadList] = useState<ThreadInfo[]>([]);
	const [models, setModels] = useState<ModelsArray | null>(null);
	const [stores, setStores] = useState<StoreDataWithPlaceholder[] | null>(null);

	const [userMessage, setUserMessage] = useState("");
	const [attachedFile, setAttachedFile] =
		useState<AssistAttachedFileType | null>(null);
	const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false);
	const [isAssistantListLoading, setIsAssistantListLoading] = useState(false);
	const [isFunctionsLoading, setIsFunctionsLoading] = useState(false);
	const [isFileLoading, setIsFileLoading] = useState(false);
	const [customLoadingMessage, setCustomLoadingMessage] = useState<
		string | null
	>(null);
	const [displayedMessages, setDisplayedMessages] = useState<
		LocalAssistantMessage[]
	>([]);

	const [assistantMode, setAssistantMode] = useState(false);
	const [messagePageCount, setMessagePageCount] = useState(0);
	const [selectedPage, setSelectedPage] = useState(1);

	const [deletingInfo, setDeletingInfo] = useState(defaultDeleteInfo);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [newFunctionDialogOpen, setNewFunctionDialogOpen] = useState(false);

	const [isReplacementToken, setIsReplacementToken] = useState(false);
	const [isRunTimedOut, setIsRunTimedOut] = useState(false);
	const runTimerRef = useRef<NodeJS.Timeout | null>(null);
	const runStatusCheckTimerRef = useRef<NodeJS.Timeout | null>(null);

	const { userInfo } = useProfile();

	// Фетч моделей и ассистентов
	useEffect(() => {
		const localIsReplacementToken = !!localStorage.getItem(
			"APP_REPLACEMENT_TOKEN_NAME"
		);
		setIsReplacementToken(localIsReplacementToken);
		setIsAssistantListLoading(true);
		getTransformedModels().then(setModels).catch(console.log);
		getStores().then((res) => {
			const newStores = res.data as StoreDataWithPlaceholder[];
			newStores.unshift({ id: "emptyId", name: "Без базы знаний" });
			setStores(res.data);
		});
		updateAssistantList().then((res) => {
			setIsFunctionsLoading(true);
			const localAssistantList: LocalAssistantInfo[] = [];
			res.forEach((assistant) => {
				let selectedResponseFormat: string;
				if (
					typeof assistant.response_format === "object" &&
					assistant.response_format.type === "json_object"
				)
					selectedResponseFormat = "json_object";
				else selectedResponseFormat = "auto";

				let localInstructions = assistant.instructions;
				if (localInstructions === " ") localInstructions = "";

				localAssistantList.push({
					...assistant,
					instructions: localInstructions,
					localFileSearchId:
						assistant.tool_resources?.file_search?.vector_store_ids[0] ||
						"emptyId",
					localResponseFormat: selectedResponseFormat,
					localFunctionInfo: [],
				});
			});

			// REPLACEMENT TOKEN SWITCH
			if (localIsReplacementToken && !!localAssistantList.length) {
				getAssistantFunctions(localAssistantList[0].id)
					.then((functions) => {
						const localFunctions = getFunctionsWithEnabled(
							functions.functions,
							localAssistantList[0].tools
						);
						localAssistantList[0].localAvailableFunctions = localFunctions;
						setAssistantList(localAssistantList);
					})
					.catch(() => {
						// Сохраняем пустой массив чтобы не делать запрос при переключении на этого ассистента
						localAssistantList[0].localAvailableFunctions = [];
						setAssistantList(localAssistantList);
					})
					.finally(() => {
						setIsFunctionsLoading(false);
						setIsAssistantListLoading(false);
					});
			} else {
				setAssistantList(localAssistantList);
				setIsFunctionsLoading(false);
				setIsAssistantListLoading(false);
			}

			const initialThreadList = createInitialThreadList(res);
			setThreadList(initialThreadList);

			if (!res?.length || !res[0].id) return;
			setSelectedAssistantId(res[0].id);
			addThreadToThreadList(initialThreadList, res[0].id).then(setThreadList);
		});

		return () => {
			if (runTimerRef.current) {
				clearTimeout(runTimerRef.current);
			}
		};
	}, []);

	// Добавление нового ассистента
	const handleAddAssistant = () => {
		if (!models) return;
		setIsAssistantListLoading(true);

		const postObj = {
			model: models?.models[0].model_name,
			name: `Ассистент ${
				!assistantList?.length ? 1 : assistantList?.length + 1
			}`,
			instructions: "",
			description: "",
			metadata: {
				education_page: "custom_assitant",
			},
			tools: [{ type: "code_interpreter" }],
			temperature: 0.5,
		};

		createAssistant(postObj).then((newAssistant) => {
			// Создание нового треда
			if (!newAssistant.id) return;
			createThreadLink(newAssistant.id).then((newThreadLink) => {
				const newThreadList = [...threadList];
				newThreadList.push(newThreadLink);
				setThreadList(newThreadList);
				setDisplayedMessages([]);
			});

			// Обновление списка ассистентов
			const newAssistantList: LocalAssistantInfo[] = [];
			if (assistantList) {
				newAssistantList.push(...assistantList);
			}

			const newAssistantCopy: LocalAssistantInfo = { ...newAssistant };
			newAssistantCopy.localFileSearchId = "emptyId";
			newAssistantCopy.localResponseFormat = "auto";

			// REPLACEMENT TOKEN SWITCH
			if (isReplacementToken) {
				getAssistantFunctions(newAssistant.id)
					.then((functions) => {
						const localFunctions = getFunctionsWithEnabled(
							functions.functions,
							newAssistant.tools
						);
						newAssistantCopy.localAvailableFunctions = localFunctions;
						setStateForNewAssistants(newAssistantCopy, newAssistantList);
					})
					.catch(() => {
						newAssistantCopy.localAvailableFunctions = [];
						setStateForNewAssistants(newAssistantCopy, newAssistantList);
					});
			} else {
				setStateForNewAssistants(newAssistantCopy, newAssistantList);
			}
		});
	};

	// Переключение ассистента
	const handleSwitchAssistant = (id: string) => {
		const selectedAssistant = assistantList?.find(
			(assistant) => assistant.id === id
		);
		const selectedThreadListItem = threadList?.find(
			(thread) => thread.assistantId === id
		);

		if (selectedThreadListItem && selectedThreadListItem.threadId === "") {
			addThreadToThreadList(
				threadList,
				selectedThreadListItem.assistantId
			).then((res) => {
				setThreadList(res);
			});
		}

		// REPLACEMENT TOKEN SWITCH
		if (isReplacementToken) {
			if (selectedAssistant && !selectedAssistant.localAvailableFunctions) {
				setIsFunctionsLoading(true);
				getAssistantFunctions(id)
					.then((functions) => {
						const localFunctions = getFunctionsWithEnabled(
							functions.functions,
							selectedAssistant.tools
						);
						const selectedAssistantCopy = { ...selectedAssistant };
						selectedAssistantCopy.localAvailableFunctions = localFunctions;
						changeAssistantList(selectedAssistantCopy);
					})
					.catch(() => {
						const selectedAssistantCopy = { ...selectedAssistant };
						selectedAssistantCopy.localAvailableFunctions = [];
						changeAssistantList(selectedAssistantCopy);
					})
					.finally(() => setIsFunctionsLoading(false));
			}
		}

		updateLocalMessages(selectedThreadListItem?.messages || []);
		setSelectedAssistantId(id);
	};

	// Удаление ассистента
	const handleAssistantDelete = (id: string) => {
		setIsAssistantListLoading(true);
		deleteAssistant(id)
			.then(() => {
				const newAssistantList = assistantList!.filter(
					(assistant) => assistant.id !== id
				);
				setAssistantList(newAssistantList);

				// Удаление треда из списка
				const deletedThread = threadList?.find(
					(thread) => thread.assistantId === id
				);
				if (deletedThread) {
					const newThreadList = threadList?.filter(
						(thread) => thread.assistantId !== id
					);

					setThreadList?.(newThreadList);
				}

				// Переключение селекта на новом списке
				if (newAssistantList?.length && newAssistantList[0].id) {
					if (selectedAssistantId === id) {
						setSelectedAssistantId(newAssistantList[0].id);
						updateLocalMessages(
							threadList.find(
								(item) => item.assistantId === newAssistantList[0].id
							)?.messages || []
						);
					}
				} else {
					setSelectedAssistantId(null);
				}
			})
			.finally(() => setIsAssistantListLoading(false));

		setDeleteDialogOpen(false);
		const timerId = setTimeout(() => {
			setDeletingInfo(defaultDeleteInfo);
		}, 300);
		return () => clearTimeout(timerId);
	};

	// Отправление сообщения ассистенту
	const handleSendMessage = () => {
		const selectedAssistant = assistantList?.find(
			(assistant) => assistant.id === selectedAssistantId
		);
		const selectedThread = threadList?.find(
			(thread) => thread.assistantId === selectedAssistantId
		);

		const messageObj: CompMessageType = {
			role: "user",
			content: userMessage,
		};

		if (
			// Проверяем на JSON режим
			selectedAssistant?.localResponseFormat === "json_object" &&
			// Проверяем на отсутствие строки "json" И в сообщении И в инструкции
			!userMessage.toLocaleLowerCase().includes("json") &&
			!selectedAssistant.instructions.toLocaleLowerCase().includes("json") &&
			// Проверяем если это первое сообщение или если мы только переключились на JSON режим
			(selectedThread?.messages.length === 0 ||
				selectedAssistant.localJustSwitchedToJson)
		) {
			showError(
				'Для ответа в формате JSON первое сообщение должно или инструкция должны содержать слово "json"'
			);
			return;
		}

		if (attachedFile) {
			const imageExtensions = [".png", ".jpg", ".jpeg", ".webp"];
			imageExtensions.forEach((ext) => {
				if (attachedFile.name.endsWith(ext)) {
					messageObj.content = [
						{
							type: "text",
							text: userMessage,
						},
						{
							type: "image_file",
							image_file: { file_id: attachedFile.id },
						},
					];
				}
			});

			messageObj.attachments = [
				{
					file_id: attachedFile.id,
					tools: [{ type: "code_interpreter" }],
				},
			];
			setAttachedFile(null);
		}

		if (userMessage !== "" && selectedThread) {
			setIsSendDisabled(true);

			// Локальное сохранение отправленного сообщения
			const localMessageObject: LocalAssistantMessage = {
				role: "user" as AssistantMessageRoles,
				content: userMessage,
				fileName: attachedFile?.name || null,
			};
			selectedThread.messages.push(localMessageObject);
			const changedThreadList = threadList.map((thread) => {
				if (thread.assistantId === selectedThread.assistantId) {
					return selectedThread;
				} else {
					return thread;
				}
			});
			setThreadList(changedThreadList);
			updateLocalMessages(selectedThread.messages, undefined, undefined, true);

			postCompMessage(selectedThread.threadId, messageObj)
				.then(() => {
					setIsRunTimedOut(false);
					if (selectedAssistant) {
						const selectedAssistantCopy = { ...selectedAssistant };
						selectedAssistantCopy.localJustSwitchedToJson = false;
						changeAssistantList(selectedAssistantCopy);
					}

					const runObj = {
						assistant_id: selectedThread.assistantId,
					};
					createRun(selectedThread.threadId, runObj)
						.then((res) => {
							checkRunStatus(res);
						})
						.catch(console.error);

					// Таймаут рана
					runTimerRef.current = setTimeout(() => {
						showError("Время ожидания ответа истекло, попробуйте ещё раз");
						setIsSendDisabled(false);
						setIsRunTimedOut(true);
					}, 120000);
				})
				.catch(console.error);
			setUserMessage("");
		}
	};

	// Прикрепление файла к сообщению
	const handleFileUpload = (e: any) => {
		if (!e.target.files[0]) return;
		setIsFileLoading(true);
		const postObj = {
			file: e.target.files[0],
			purpose: "assistants" as FilePurpose,
		};

		uploadFile(postObj)
			.then((res) => {
				setAttachedFile({
					id: res.id,
					name: res.filename,
				});
			})
			.finally(() => setIsFileLoading(false));
	};

	// Удаление сообщений
	const handleClearChat = () => {
		setUserMessage("");

		const changedThreadInfo = threadList?.find((thread) => {
			return thread.assistantId === selectedAssistantId;
		});

		if (changedThreadInfo) {
			const newThreadList = threadList?.filter(
				(thread) => thread.assistantId !== selectedAssistantId
			);

			createThread().then((res) => {
				const newThreadInfo = {
					assistantId: selectedAssistantId || "",
					threadId: res.id,
					messages: [],
				};

				newThreadList?.push(newThreadInfo);
				setThreadList(newThreadList);
				setDisplayedMessages([]);
				setMessagePageCount(0);
			});
		}
	};

	// Переключение режима ассистента
	const handleAssistantModeChange = (value: string) => {
		const localAssistantMode = value === "assistant";
		setAssistantMode(localAssistantMode);

		const selectedThreadItem = threadList?.find(
			(thread) => thread.assistantId === selectedAssistantId
		);

		updateLocalMessages(selectedThreadItem?.messages || [], localAssistantMode);
	};

	// Переключение страницы в режиме ассистента
	const handlePageChange = (pageNumber: number) => {
		const selectedThread = threadList?.find(
			(thread) => thread.assistantId === selectedAssistantId
		);

		updateLocalMessages(
			selectedThread?.messages || [],
			assistantMode,
			pageNumber
		);
	};

	// Добавление новой функции
	const handleAddFunction = (
		url: string,
		friendlyName: string,
		isChecked: boolean
	) => {
		if (!selectedAssistantId || !assistantList) return;
		const selectedAssistant = assistantList.find(
			(assistant) => assistant.id === selectedAssistantId
		);
		if (!selectedAssistant) return;
		const assistantCopy = { ...selectedAssistant };

		const functionName = url.split("/").pop();
		if (!functionName) {
			showError("Не удалось получить название функции из ссылки");
			return;
		}
		if (
			selectedAssistant.localAvailableFunctions?.find(
				(func) => func.function_name === functionName
			)
		) {
			showError("Эта функция уже добавлена для ассистента");
			return;
		}

		getAssistantFunctionSchema(selectedAssistantId, functionName)
			.then((schema) => {
				const functionPostObj = {
					function_name: functionName,
					endpoint: url,
					credentials: isChecked,
					friendly_name: friendlyName,
					asst_name: selectedAssistant.id,
				};

				addAssistantFunction(functionPostObj).then(() => {
					const localFunctionObject = {
						function_name: functionName,
						friendly_name: friendlyName,
						enabled: true,
						asst_name: selectedAssistant.id,
					};
					assistantCopy.localAvailableFunctions?.push(localFunctionObject);

					const functionInfoObject = {
						type: "function",
						function: schema.schema,
					};
					assistantCopy.localFunctionInfo?.push(functionInfoObject);
					assistantCopy.tools.push(functionInfoObject);

					changeAssistantList(assistantCopy);
					updateAssistant(
						selectedAssistantId,
						getFilteredAssistantToPost(assistantCopy)
					);
				});
			})
			.catch(() => {
				showError("Функция с таким url не найдена");
			});
	};

	// Проверка статуса рана
	const checkRunStatus = (run: RunInfo) => {
		const selectedThread = threadList?.find(
			(thread) => thread.assistantId === selectedAssistantId
		);
		// TODO: Поменять run.status чеки на switch case
		if (selectedThread && !isRunTimedOut) {
			if (run.status === "in_progress" || run.status === "queued") {
				runStatusCheckTimerRef.current = setTimeout(() => {
					getRunStatus(selectedThread.threadId, run?.id).then((res) =>
						checkRunStatus(res)
					);
				}, 1000);

				return () => {
					if (runStatusCheckTimerRef.current)
						clearTimeout(runStatusCheckTimerRef.current);
				};
			}
			if (run.status === "requires_action") {
				if (!isReplacementToken) {
					showError("Функции недоступны для этого пользователя");
					setIsSendDisabled(false);
					if (runTimerRef.current) {
						clearTimeout(runTimerRef.current);
					}
					return;
				}

				updateRunWithAssistantFunctions(run, selectedThread);
			}
			if (run.status === "completed") {
				getAndSaveAssistantMessages(selectedThread);
			}
			if (run.status === "failed") {
				showError("Что-то пошло не так, попробуйте отправить сообщение снова");
				setIsSendDisabled(false);
				if (runTimerRef.current) {
					clearTimeout(runTimerRef.current);
				}
			}
		}
	};

	//================================================= UTILITY FUNCTIONS =================================================//

	// Обновление тред листа и списка сообщений для обновления статуса рана
	const updateThreadsAndMessages = (
		selectedThreadCopy: ThreadInfo,
		content: string
	) => {
		const selectedAssistant = assistantList?.find(
			(assistant) => assistant.id === selectedThreadCopy.assistantId
		);

		let newMessageObject: LocalAssistantMessage = {
			role: "assistant" as AssistantMessageRoles,
			content: content,
		};
		if (
			selectedAssistant &&
			selectedAssistant.localResponseFormat === "json_object"
		) {
			newMessageObject.isJson = true;
		}
		selectedThreadCopy.messages.push(newMessageObject);

		const newThreadList = threadList.map((thread) =>
			thread.assistantId === selectedThreadCopy.assistantId
				? selectedThreadCopy
				: thread
		);

		setThreadList(newThreadList);
		updateLocalMessages(selectedThreadCopy.messages);

		if (runTimerRef.current) {
			clearTimeout(runTimerRef.current);
		}
	};

	// Обновление списка сообщений учитывая режим ассистента
	const updateLocalMessages = (
		newMessages: LocalAssistantMessage[],
		newAssistantMode?: boolean,
		newPageNumber?: number,
		newIsSendDisabled?: boolean
	) => {
		const localAssistantMode =
			newAssistantMode === undefined ? assistantMode : newAssistantMode;
		const assistantModeMessagesObject = getAssistantModeMessages(
			newMessages,
			localAssistantMode,
			newIsSendDisabled,
			newPageNumber
		);

		setDisplayedMessages(assistantModeMessagesObject.messages);
		setMessagePageCount(assistantModeMessagesObject.pageCount);
		setSelectedPage(assistantModeMessagesObject.pageCount);
		if (newPageNumber) {
			setSelectedPage(newPageNumber);
		}
	};

	// Простое изменение стейта assistantList
	const changeAssistantList = (newAssistant: LocalAssistantInfo) => {
		if (!assistantList) return;
		const newAssistantList = assistantList?.map((assistant) =>
			assistant.id === newAssistant.id ? newAssistant : assistant
		);

		const sortedAssistantList = sortAssistantData(newAssistantList);
		setAssistantList(sortedAssistantList);
	};

	// Добавления нового ассистента в assistantList стейт
	const setStateForNewAssistants = (
		newAssistant: LocalAssistantInfo,
		newAssistantList: LocalAssistantInfo[]
	) => {
		const newAssistantListCopy = [...newAssistantList];
		newAssistantListCopy.push(newAssistant);
		const newSortedAssistantList = sortAssistantData(newAssistantListCopy);

		setAssistantList(newSortedAssistantList);
		setSelectedAssistantId(newAssistant.id);
		setMessagePageCount(0);
		setIsAssistantListLoading(false);
	};

	// Запуск функций ассистента и обновление рана при вызове функций в сообщении
	const updateRunWithAssistantFunctions = (
		run: RunInfo,
		selectedThread: ThreadInfo
	) => {
		setCustomLoadingMessage("Выполняются функции...");
		const replacementToken = localStorage.getItem(
			"APP_REPLACEMENT_TOKEN_NAME"
		) as string;
		let promises: Promise<{
			output: AssistantFunctionRunOutput;
			toolId: string;
		}>[] = [];
		const assistantMessageId = selectedThread.assistantId;
		const messageThreadId = selectedThread.threadId;

		run.required_action.submit_tool_outputs.tool_calls.forEach((tool) => {
			const postObject: AssistantFunctionRunPostObjType = {
				params: JSON.parse(tool.function.arguments),
				credentials: {
					openai_key: replacementToken,
					base_url: userInfo?.thread_url || "",
					asst_id: assistantMessageId,
					thread_id: messageThreadId,
				},
			};

			promises.push(
				runAssistantFunction(tool.function.name, postObject).then((res) => ({
					output: res,
					toolId: tool.id,
				}))
			);
		});

		Promise.all(promises).then((res) => {
			setCustomLoadingMessage("Отправляются результаты...");
			const payloads: AssistantFunctionToolOutput[] = [];
			res.forEach(({ output, toolId }) => {
				const functionOutput = output.function_output;
				const payload = {
					tool_call_id: toolId,
					output: functionOutput ?? "Не найдено, попробовать позже",
				};
				payloads.push(payload);
			});

			const postObj = { tool_outputs: payloads };
			submitAssistantToolOutputs(messageThreadId, run.id, postObj).then(
				(res) => {
					checkRunStatus(res);
					setCustomLoadingMessage(null);
				}
			);
		});
	};

	// Получение и сохранение сообщений при успешном статусе рана
	const getAndSaveAssistantMessages = (selectedThread: ThreadInfo) => {
		getMessages(selectedThread.threadId).then((messages) => {
			// Обрабатываем только последнее сообщение
			const fetchedMessage = messages.data[
				messages.data.length - 1
			].content.find((item) => item.type === "text");
			const fetchedImage = messages.data[messages.data.length - 1].content.find(
				(item) => item.type === "image_file"
			);
			if (!fetchedMessage) return;
			let newMessage = fetchedMessage.text.value;
			const linkedFiles = [...fetchedMessage.text.annotations];
			const selectedThreadCopy = { ...selectedThread };

			// TODO: Настроить отображение изображений приходящих в отдельном content.type = "image_file"
			// if (fetchedImage) {
			//   const imageFileId = fetchedImage.image_file?.file_id;
			//   console.log("imageFileId", imageFileId);
			// }

			// Проверка на привязанные к сообщению файлы
			if (linkedFiles.length) {
				const promises: Promise<[Blob, string]>[] = linkedFiles
					.filter((linkedFile) => linkedFile.file_path?.file_id) // Фильтруем только файлы с file_id
					.map((linkedFile) => {
						const fileId = linkedFile.file_path.file_id;
						const fileName = linkedFile.text.slice(
							linkedFile.text.lastIndexOf("/") + 1
						);
						return getFileContent(fileId).then((blob) => [blob, fileName]);
					});

				Promise.all(promises)
					.then((res) => {
						res.forEach(([blob, fileName]) => {
							const newUrl = URL.createObjectURL(blob);
							// TODO: Название файла стоит передавать в новом localAssistantMessage.fileName и
							// настроить правильное отображение файла в сообщениях AppChatMessage
							const urlWithFileName = `#downloadlink===${fileName}===${newUrl}`;

							// Находим индекс первой sandbox ссылки в сообщении
							const linkRegex = /\]\(sandbox:/;
							const match = newMessage.match(linkRegex);
							const startLinkIndex = match?.index;
							if (!startLinkIndex) return;
							const endLinkIndex = newMessage.indexOf(")", startLinkIndex);

							if (startLinkIndex !== -1 && endLinkIndex !== -1) {
								const newLink = newMessage.substring(
									startLinkIndex + 2,
									endLinkIndex
								);
								newMessage = newMessage.replace(newLink, urlWithFileName);
							}
						});

						updateThreadsAndMessages(selectedThreadCopy, newMessage);
					})
					.finally(() => setIsSendDisabled(false));
			} else {
				updateThreadsAndMessages(selectedThreadCopy, newMessage);
				setIsSendDisabled(false);
			}
		});
	};

	// Открытие диалогового окна
	const handleDialogOpen = (type: string, id?: string, name?: string) => {
		if (type === "delete" && id && name) {
			setDeletingInfo({ id, name });
			setDeleteDialogOpen(true);
		}
		if (type === "new_function") {
			setNewFunctionDialogOpen(true);
		}
	};

	// Закрытие диалогового окна
	const handleDialogClose = () => {
		setDeleteDialogOpen(false);
		setNewFunctionDialogOpen(false);
		const timerId = setTimeout(() => {
			setDeletingInfo(defaultDeleteInfo);
		}, 300);

		return () => clearTimeout(timerId);
	};

	// Добавление параметра для открытия диалогового окна из сайдбара
	const addSidebarDialogData = (id: string, name: string) => {
		const type = "delete";
		handleDialogOpen(type, id, name);
	};

	// Testing
	useEffect(() => {
		console.log(assistantList);
	}, [assistantList]);

	return (
		<div className={styles["page-layout"]}>
			<AppSidebar
				className={styles.sidebar}
				addButton
				deleteButton
				menuItems={
					assistantList?.map((assistant) => ({
						id: assistant.id,
						name: assistant.name,
					})) || []
				}
				defaultName="Ассистент"
				selectedId={selectedAssistantId}
				onSelect={handleSwitchAssistant}
				onAdd={handleAddAssistant}
				handleDialogOpen={addSidebarDialogData}
				isSendDisabled={isSendDisabled}
				isLoading={isAssistantListLoading}
			/>
			{selectedAssistantId && assistantList && (
				<ResizableWrapper
					hasButton={true}
					leftBlock={
						<AssistMain
							className={styles["main"]}
							models={models ? models.models : null}
							selectedAssistant={
								assistantList.filter(
									(assistant) => assistant.id === selectedAssistantId
								)[0]
							}
							changeAssistantList={changeAssistantList}
							stores={stores}
							handleDialogOpen={handleDialogOpen}
							isFunctionsLoading={isFunctionsLoading}
							isReplacementToken={isReplacementToken}
							isSendDisabled={isSendDisabled}
						/>
					}
					rightBlock={
						<AssistChat
							className={styles["chat"]}
							userMessage={userMessage}
							setUserMessage={setUserMessage}
							handleSend={handleSendMessage}
							handleFileUpload={handleFileUpload}
							isSendDisabled={isSendDisabled}
							assistantMode={assistantMode}
							messagePageCount={messagePageCount}
							selectedPage={selectedPage}
							handlePageChange={handlePageChange}
							handleClearChat={handleClearChat}
							handleAssistantModeChange={handleAssistantModeChange}
							displayedMessages={displayedMessages}
							attachedFile={attachedFile}
							setAttachedFile={setAttachedFile}
							isFileLoading={isFileLoading}
							customLoadingMessage={customLoadingMessage}
						/>
					}
					leftBlockSize={30}
					rightBlockSize={70}
					rightBlockMinSize={50}
				/>
			)}
			<AppDeleteDialog
				dialogOpen={deleteDialogOpen}
				deletingInfo={deletingInfo}
				handleDialogClose={handleDialogClose}
				onDelete={handleAssistantDelete}
			/>
			<AppNewFunctionDialog
				dialogOpen={newFunctionDialogOpen}
				handleDialogClose={handleDialogClose}
				handleAddFunction={handleAddFunction}
			/>
		</div>
	);
};

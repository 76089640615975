import { useEffect, useState, type FC, type ReactNode } from "react";
import styles from "./styles.module.css";
import {
	PanelProps,
	PanelGroup,
	Panel,
	PanelResizeHandle,
} from "react-resizable-panels";
import { ButtonCollapse } from "./ButtonCollapse";
import classNames from "classnames";
interface Props extends PanelProps {
	leftBlock: ReactNode;
	rightBlock: ReactNode;
	leftBlockSize?: number;
	rightBlockSize?: number;
	leftBlockMinSize?: number;
	rightBlockMinSize?: number;
	hasButton?: boolean;
	isCollapsible?: boolean;
}

export const ResizableWrapper: FC<Props> = ({
	leftBlock,
	rightBlock,
	leftBlockSize,
	rightBlockSize,
	leftBlockMinSize,
	rightBlockMinSize,
	hasButton,
	isCollapsible,
	...props
}) => {
	const [minSize, setMinSize] = useState<number>();

	const handleCollapsed = () => {
		setMinSize(() => (minSize === 0 ? 30 : 0));
	};

	useEffect(() => {}, []);

	return (
		<PanelGroup direction="horizontal" className={styles.wrapper}>
			<Panel
				collapsible={isCollapsible}
				defaultSize={leftBlockSize}
				minSize={leftBlockMinSize}
				className={styles["resize-panel-left"]}
				style={{ transition: "flex 0.2s ease", flex: `${minSize} 1 0` }}
				{...props}
			>
				{leftBlock}
			</Panel>
			<PanelResizeHandle children={<div className={styles["resize"]}></div>} />
			<Panel
				defaultSize={rightBlockSize}
				minSize={rightBlockMinSize}
				className={styles["resize-panel-right"]}
				{...props}
			>
				{hasButton && (
					<ButtonCollapse
						className={classNames({ [styles.collapsed]: minSize === 0 })}
						onClick={handleCollapsed}
					/>
				)}
				{rightBlock}
			</Panel>
		</PanelGroup>
	);
};

import { AppDialog } from "..";
import { AppButton } from "../../AppButton";
import { AppLabel } from "../../AppLabel";
import { AppInput } from "../../AppInput";
import { AppCheckbox } from "../../AppCheckbox";
import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  dialogOpen: boolean;
  handleDialogClose: () => void;
  handleAddFunction: (
    input: string,
    friendlyName: string,
    isChecked: boolean
  ) => void;
}

export const AppNewFunctionDialog: React.FC<Props> = ({
  className,
  dialogOpen,
  handleDialogClose,
  handleAddFunction,
}) => {
  const [url, setUrl] = useState<string>("");
  const [functionName, setFunctionName] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const closeDialog = () => {
    handleDialogClose();
    const timeout = setTimeout(() => {
      setUrl("");
      setFunctionName("");
      setIsChecked(true);
    }, 500);

    return () => clearTimeout(timeout);
  };

  const localAddFunction = () => {
    let newUrl = url.trim();
    if (newUrl.startsWith("/")) newUrl = newUrl.slice(1);
    handleAddFunction(newUrl, functionName.trim(), isChecked);
    closeDialog();
  };

  const disabledCheck = (): boolean => {
    const isUrlValid =
      url.trim().length > 0 &&
      !url.trim().includes(" ") &&
      url.trim().startsWith("http");
    const isFunctionNameValid = functionName.trim().length > 0;
    return !(isUrlValid && isFunctionNameValid);
  };

  return (
    <AppDialog
      classes={{ paper: classNames(styles.dialog, className) }}
      open={dialogOpen}
    >
      <h1 className={styles["dialog-title"]}>Добавить функцию</h1>
      <div className={styles["input-container"]}>
        <AppLabel className={styles.label}>URL функции</AppLabel>
        <AppInput value={url} onChange={(e) => setUrl(e.target.value)} />
      </div>
      <div className={styles["input-container"]}>
        <AppLabel className={styles.label}>Название функции</AppLabel>
        <AppInput
          value={functionName}
          onChange={(e) => setFunctionName(e.target.value)}
        />
      </div>
      <div className={classNames(styles["check-container"])}>
        <AppCheckbox
          checked={isChecked}
          onClick={() => setIsChecked(!isChecked)}
        >
          Передавать credentials
        </AppCheckbox>
      </div>

      <div className={styles["dialog-button-container"]}>
        <AppButton small buttonType="outlined" onClick={closeDialog}>
          Отмена
        </AppButton>
        <AppButton
          small
          buttonType="contained"
          onClick={localAddFunction}
          disabled={disabledCheck()}
        >
          Добавить
        </AppButton>
      </div>
    </AppDialog>
  );
};

let classificatorFolderId = "";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
	classificatorFolderId =
		process.env.REACT_APP_CLASSICATOR_TOOLS_FOLDER_ID || "";
} else {
	classificatorFolderId =
		// @ts-ignore
		window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_FOLDER_ID;
}

let classificatorKey = "";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
	classificatorKey = process.env.REACT_APP_CLASSICATOR_TOOLS_API_KEY || "";
} else {
	classificatorKey =
		// @ts-ignore
		window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_API_KEY;
}

let classificatorModel = "yandexgpt/latest";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
	classificatorModel = process.env.REACT_APP_CLASSICATOR_TOOLS_MODEL || "";
} else {
	classificatorModel =
		// @ts-ignore
		window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_MODEL;
}

export const APP_ACCESS_TOKEN_NAME = "APP_ACCESS_TOKEN";
export const APP_REPLACEMENT_TOKEN_NAME = "APP_REPLACEMENT_TOKEN_NAME";
export const APP_REFRESH_TOKEN_NAME = "APP_REFRESH_TOKEN";
export const KEYCLOAK = "KEYCLOAK";

export { classificatorFolderId, classificatorKey, classificatorModel };

import { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import { LockIcon } from "../../shared/icons/LockIcon";
import { EyeIcon } from "../../shared/icons/EyeIcon";
import { EyeInvisibleIcon } from "../../shared/icons/EyeInvisibleIcon";
import { AppIconButton } from "../../shared/components/AppIconButton";
import { UserIcon } from "../../shared/icons/UserIcon";
import { AppButton } from "../../shared/components/AppButton";
import { AppInput } from "../../shared/components/AppInput";
import { getUserInfo, login } from "../../services/api/methods";
import {
	APP_ACCESS_TOKEN_NAME,
	APP_REFRESH_TOKEN_NAME,
	APP_REPLACEMENT_TOKEN_NAME,
	KEYCLOAK,
} from "../../services/api/constants";
import { useProfile } from "../../services/profile";
import { recreateAppThreadApi } from "../../services/api/instances/threadApiInstance";
import squares from "../../shared/assets/img/squares.png";
import logo from "../../shared/assets/img/logo_light.png";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { routesArray } from "../../app/routing";
import { recreateAppModelsApi } from "../../services/api/instances/modelsApiInstance";
import { recreateAppBotApi } from "../../services/api/instances/botApiInstance";

const startWrongData = {
	username: false,
	password: false,
	credentials: false,
};

export const LoginPage = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const [wrongData, setWrongData] = useState(startWrongData);
	const { setUserInfo } = useProfile();
	const navigate = useNavigate();

	const handlePasswordVisibility = () => {
		setPasswordVisible(!isPasswordVisible);
	};

	const onSubmit = (e: any) => {
		e.preventDefault();

		login(username, password)
			.then((res) => {
				localStorage.setItem(APP_ACCESS_TOKEN_NAME, res.access_token);
				localStorage.setItem(APP_REFRESH_TOKEN_NAME, res.refresh_token);
				getUserInfo().then((res) => {
					if (res.replacement_token) {
						localStorage.setItem(
							APP_REPLACEMENT_TOKEN_NAME,
							res.replacement_token
						);
					} else {
						localStorage.removeItem(APP_REPLACEMENT_TOKEN_NAME);
					}
					recreateAppThreadApi(
						setUserInfo,
						!!res.replacement_token,
						res.thread_url
					);
					recreateAppModelsApi(setUserInfo, res.thread_model_url);
					recreateAppBotApi(
						setUserInfo,
						!!res.replacement_token,
						res.tg_bot_service_url
					);
					setUserInfo(res);

					const firstGroup = res["user-groups"][0];
					const firstUrl =
						routesArray.find((route) => route.link === firstGroup)?.path || "/";

					navigate(firstUrl);
				});
			})
			.catch((error) => {
				console.log(error);

				let tempWrongData;
				tempWrongData = startWrongData;

				if (!username) {
					tempWrongData = { ...tempWrongData, username: true };
					setWrongData(tempWrongData);
					return;
				}
				if (!password) {
					tempWrongData = { ...tempWrongData, password: true };
					setWrongData(tempWrongData);
					return;
				}

				tempWrongData = { ...tempWrongData, credentials: true };
				setWrongData(tempWrongData);
			});
	};

	const handleErrorRendering = () => {
		if (wrongData.username) {
			return <p className={styles.error}>Введите логин</p>;
		}
		if (wrongData.password) {
			return <p className={styles.error}>Введите пароль</p>;
		}
		if (wrongData.credentials) {
			return <p className={styles.error}>Неверный логин или пароль</p>;
		}
	};

	return (
		<div className={styles["page-layout"]}>
			<img className={styles.logo} src={logo} alt="Логотип Гуреев" />
			<div className={styles.container}>
				<h1 className={styles.title}>Инструменты для работы с ИИ</h1>
				<AppInput
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					startAdornment={
						<InputAdornment position="start">
							<UserIcon />
						</InputAdornment>
					}
					placeholder="Логин"
					className={styles.input}
					inputClassName={styles["inner-input"]}
				/>
				<AppInput
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type={isPasswordVisible ? "text" : "password"}
					startAdornment={
						<InputAdornment position="start">
							<LockIcon />
						</InputAdornment>
					}
					endAdornment={
						<InputAdornment position="end">
							<AppIconButton
								onClick={handlePasswordVisibility}
								className={styles["eye-icon-button"]}
							>
								{isPasswordVisible ? (
									<EyeIcon className={styles["eye-icon"]} />
								) : (
									<EyeInvisibleIcon className={styles["eye-icon"]} />
								)}
							</AppIconButton>
						</InputAdornment>
					}
					placeholder="Пароль"
					className={styles.input}
					inputClassName={styles["inner-input"]}
				/>
				{handleErrorRendering()}
				{/* <div className={styles["checks-container"]}>
          <AppCheckbox
            className={styles["remember-me-input"]}
            labelClassName={styles["remember-me-label"]}
          >
            Запомнить меня
          </AppCheckbox>
          <a className={styles["forgot-password"]} href="#">
            Забыли пароль?
          </a>
        </div> */}
				<div className={styles["button-container"]}>
					<AppButton
						onClick={onSubmit}
						className={styles["login-button"]}
						buttonType="contained"
					>
						Войти
					</AppButton>
					{/* <AppButton className={styles["register-button"]} buttonType="text">
            Зарегистрироваться
          </AppButton> */}
				</div>
			</div>
			<img className={styles["background-squares"]} src={squares} alt="" />
		</div>
	);
};

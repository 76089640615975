import axios from "axios";
import { getThreadAccessToken } from "../utils";
import { APP_ACCESS_TOKEN_NAME, APP_REFRESH_TOKEN_NAME } from "../constants";

let APP_THREAD_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
	APP_THREAD_API_URL = process.env.REACT_APP_THREAD_URL || "/";
} else {
	// @ts-ignore
	APP_THREAD_API_URL = window.__RUNTIME_CONFIG__?.REACT_APP_THREAD_URL;
}

export let appThreadApi = axios.create({
	baseURL: APP_THREAD_API_URL,
});

export const recreateAppThreadApi = (
	setUserInfo: (value: null) => void,
	replacementToken: boolean,
	baseUrl?: string
) => {
	appThreadApi = axios.create({
		baseURL: baseUrl ? baseUrl : APP_THREAD_API_URL,
	});

	appThreadApi.interceptors.request.use((request) => {
		if (request.headers) {
			request.headers["Authorization"] = `Bearer ${getThreadAccessToken()}`;
			request.headers["X-Keycloak-Token"] = localStorage.getItem(
				APP_ACCESS_TOKEN_NAME
			);
		}
		if (replacementToken) {
			request.headers["OpenAI-Beta"] = "assistants=v2";
		}
		return request;
	});

	appThreadApi.interceptors.response.use(
		(response) => {
			return response;
		},
		function (error) {
			if (error.response.status === 401) {
				localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
				localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
				setUserInfo(null);
			}
			return Promise.reject(error);
		}
	);
};

import { AppIconButton } from "../../AppIconButton";
import { ArrowIcon } from "../../../icons/ArrowIcon";
import styles from "./styles.module.css";
import classNames from "classnames";

interface Props {
	onClick: () => void;
	className?: string;
}
export const ButtonCollapse: React.FC<Props> = ({ onClick, className }) => {
	return (
		<AppIconButton
			className={classNames(styles["hide-button"], className)}
			onClick={onClick}
		>
			<ArrowIcon className={styles.icon} />
		</AppIconButton>
	);
};

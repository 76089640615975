import { useEffect, useState } from "react";
import { ChatbotMain } from "../../common/Chatbot/ChatbotMain";
import { AppSidebar, MenuItem } from "../../shared/components/AppSidebar";
import {
	AssistantInfo,
	BotObject,
	ChatListObject,
	ChatMessageObject,
	ChatObject,
	NewBotObject,
} from "../../services/api/methodsTypes";
import {
	createBot,
	deleteBot,
	getAssistants,
	getBotChats,
	getBots,
	updateBot,
	updateChatAssistant,
	verifyBot,
} from "../../services/api/methods";
import {
	AppDeleteDialog,
	defaultDeleteInfo,
} from "../../shared/components/AppDialog/AppDeleteDialog";
import { AppNewBotDialog } from "../../shared/components/AppDialog/Chatbots/AppNewBotDialog";
import {
	resetTokenErrors,
	setIsTokenExisting,
	setIsTokenWrong,
	startTokenErrors,
	TokenError,
} from "../../shared/utils/chatbotFunctions";
import { getUniqueStrings } from "../../shared/utils/getUniqueStrings";
import { AppChangeBotDialog } from "../../shared/components/AppDialog/Chatbots/AppChangeBotDialog";
import { generateStringId } from "../../shared/utils/generateStringId";
import { getFilteredBotToPost } from "../../shared/utils/chatbotFunctions";
import { ChatbotDialogs } from "../../common/Chatbot/ChatbotDialogs";
import { AppChangeTableAssistantDialog } from "../../shared/components/AppDialog/Chatbots/AppChangeTableAssistantDialog";
import styles from "./styles.module.css";
import { ResizableWrapper } from "../../shared/components/ResizableWrapper";

export type SelectedUserListType = {
	selectId: string;
	id: string;
	name: string;
};

export type UserListType = {
	ref: string;
	userOptions: MenuItem[];
	selectedBlacklistUsers: SelectedUserListType[];
	selectedWhitelistUsers: SelectedUserListType[];
};

export type ChatObjectWithBlocked = ChatObject & {
	isBlocked?: boolean;
	messages?: ChatMessageObject[];
};

export type AssistantInfoWithPlaceholder = {
	id: AssistantInfo["id"];
	name: AssistantInfo["name"];
} & Partial<Omit<AssistantInfo, "id" | "name">>;

export type ChatbotWithChats = BotObject & {
	chats?: ChatObjectWithBlocked[];
	selectedChat?: string;
	dateStart?: Date | null;
	dateEnd?: Date | null;
};

export const ChatbotPage = () => {
	const [chatbotList, setChatbotList] = useState<ChatbotWithChats[] | null>(
		null
	);
	const [selectedSidebarItem, setSelectedSidebarItem] = useState<string | null>(
		null
	);
	const [assistantList, setAssistantList] = useState<
		AssistantInfoWithPlaceholder[] | null
	>(null);
	const [secureBotList, setSecureBotList] = useState<string[] | null>(null);
	const [userList, setUserList] = useState<UserListType[] | null>(null);

	const [deletingInfo, setDeletingInfo] = useState(defaultDeleteInfo);
	const [changingChatId, setChangingChatId] = useState<string | null>(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [newBotDialogOpen, setNewBotDialogOpen] = useState(false);
	const [changeBotDialogOpen, setChangeBotDialogOpen] = useState(false);
	const [changeAssistantDialogOpen, setChangeAssistantDialogOpen] =
		useState(false);
	const [token, setToken] = useState("");
	const [tokenErrors, setTokenErrors] =
		useState<TokenError[]>(startTokenErrors);
	const [isChatbotListLoading, setIsChatbotListLoading] = useState(false);
	const [isChatsLoading, setIsChatsLoading] = useState(false);
	const [isTokenCheckLoading, setIsTokenCheckLoading] = useState(false);

	// Загрузка данных
	useEffect(() => {
		setIsChatbotListLoading(true);
		getBots()
			.then((bots) => {
				const newChatbotList: ChatbotWithChats[] = [];
				bots.tg_bots.forEach((bot) => {
					const today = new Date();
					const monthAgo = new Date();
					monthAgo.setMonth(today.getMonth() - 1);

					newChatbotList.push({
						...bot,
						asst_name: bot.asst_name || "emptyId",
						secure_bot_name: bot.secure_bot_name || "Без бота",
						secure_chat_id: bot.secure_chat_id || "emptyId",
						selectedChat: "emptyId",
						dateStart: monthAgo,
						dateEnd: today,
					});
				});

				if (newChatbotList.length) {
					setSelectedSidebarItem(bots.tg_bots[0].ref);
					const selectedBot = newChatbotList[0];

					// Получение и сохранение чатов первого бота и secure_bot (если есть)
					setIsChatsLoading(true);
					const promises = [getBotChats(selectedBot.ref)];
					let localSecureBot: ChatbotWithChats | undefined;

					if (selectedBot.secure_bot_name !== "Без бота") {
						localSecureBot = newChatbotList.find(
							(bot) => bot.bot_name === selectedBot.secure_bot_name
						);
						if (localSecureBot) {
							promises.push(getBotChats(localSecureBot.ref));
						}
					}

					Promise.all(promises)
						.then(([selectedBotChats, secureBotChats]) => {
							// Обновление чатов выбранного бота
							const newChats: ChatObjectWithBlocked[] =
								selectedBotChats.chats.map((chat) => ({
									...chat,
									isBlocked: false,
									messages: [],
								}));
							selectedBot.chats = newChats;

							// Заполнение локального userList для работы с whitelist и blacklist
							const newUserList: UserListType[] = [];
							if (userList) {
								newUserList.push(...userList);
							}
							const newUserListObject = getLocalUserListObject(
								selectedBot,
								selectedBotChats
							);
							newUserList.push(newUserListObject);
							setUserList(newUserList);

							// Обновление чатов secure_bot'а если найден localSecureBot
							if (secureBotChats && localSecureBot) {
								const secureBotCopy = { ...localSecureBot };
								const newSecureChats: ChatObjectWithBlocked[] =
									secureBotChats.chats.map((chat) => ({
										...chat,
										isBlocked: false,
										messages: [],
									}));
								secureBotCopy.chats = newSecureChats;

								// Обновление newChatbotList для secure_bot'а
								const secureBotIndex = newChatbotList.findIndex(
									(bot) => bot.ref === secureBotCopy.ref
								);
								if (secureBotIndex !== -1) {
									newChatbotList[secureBotIndex] = secureBotCopy;
								}
							}

							setChatbotList([...newChatbotList]);
						})
						.finally(() => setIsChatsLoading(false));
				} else setChatbotList([]);

				// Создание списка для выбора имени бота администратора
				const botNames = bots.tg_bots.map((bot) => bot.bot_name);
				const uniqueNames = getUniqueStrings(botNames);
				uniqueNames.unshift("Без бота");
				setSecureBotList(uniqueNames);

				getAssistants().then((assistants) => {
					const newAssistantList =
						assistants.data as AssistantInfoWithPlaceholder[];
					newAssistantList.unshift({
						id: "emptyId",
						name: "Без ассистента",
					});
					setAssistantList(newAssistantList);
				});
			})
			.finally(() => setIsChatbotListLoading(false));
	}, []);

	// Проверка токена
	const handleTokenVerification = (type: "create" | "edit") => {
		if (!chatbotList) return;
		setIsTokenCheckLoading(true);
		const newTokenErrors = localResetTokenErrors();
		verifyBot(token)
			.then((res) => {
				const name = res.result?.username || "";
				if (!res.ok) {
					localSetIsTokenWrong(true, newTokenErrors);
					return;
				}
				if (chatbotList?.find((bot) => bot.bot_api === token)) {
					localSetIsTokenExisting(true, newTokenErrors);
					return;
				}

				if (type === "create") {
					handleAddBot(token, name);
				}
				if (type === "edit") {
					const oldBot = chatbotList.find(
						(bot) => bot.ref === selectedSidebarItem
					);
					handleEditBot(token, name, oldBot);
				}
				handleDialogClose();
			})
			.finally(() => setIsTokenCheckLoading(false));
	};

	// Создание бота
	const handleAddBot = (token: string, name: string) => {
		if (!chatbotList) return;
		setIsChatbotListLoading(true);
		const newChatbotItem: NewBotObject = {
			bot_name: name,
			bot_api: token,
		};

		createBot(newChatbotItem)
			.then((res) => {
				const oldList: ChatbotWithChats[] = [];
				if (chatbotList?.length) oldList.push(...chatbotList);

				const today = new Date();
				const monthAgo = new Date();
				monthAgo.setMonth(today.getMonth() - 1);

				const newBot = {
					...res,
					asst_name: "emptyId",
					start_message: "Привет!",
					secure_bot_name: "Без бота",
					selectedChat: "emptyId",
					dateStart: monthAgo,
					dateEnd: today,
					chats: [],
				};
				oldList.unshift(newBot);
				setChatbotList(oldList);
				setSelectedSidebarItem(res.ref);

				// Обновление secureBotList
				const oldSecureBotList = [];
				if (secureBotList) oldSecureBotList.push(...secureBotList);
				const filteredBotList = getUniqueStrings(oldSecureBotList);
				filteredBotList.splice(1, 0, res.bot_name);
				setSecureBotList(getUniqueStrings(filteredBotList));

				// Обновление userList
				const EMPTY_USER: MenuItem = {
					id: "emptyId",
					name: "Без пользователя",
				};
				const newUserList: UserListType[] = [];
				const newUserListObject: UserListType = {
					ref: res.ref,
					userOptions: [EMPTY_USER],
					selectedBlacklistUsers: [
						{
							selectId: generateStringId(),
							...EMPTY_USER,
						},
					],
					selectedWhitelistUsers: [
						{
							selectId: generateStringId(),
							...EMPTY_USER,
						},
					],
				};
				if (userList) newUserList.push(...userList, newUserListObject);
				setUserList(newUserList);

				const newPostBot = {
					...newBot,
					asst_name: null,
					secure_bot_name: null,
				};
				updateBot(res.ref, getFilteredBotToPost(newPostBot));
			})
			.finally(() => setIsChatbotListLoading(false));
	};

	// Изменение API бота
	const handleEditBot = (token: string, name: string, oldBot?: BotObject) => {
		if (!chatbotList || !selectedSidebarItem) return;
		let newBot = chatbotList.find((item) => item.ref === selectedSidebarItem);

		if (!newBot) return;
		newBot = {
			...newBot,
			bot_api: token,
			bot_name: name,
		};
		const newBotList = chatbotList.map((item) => {
			if (item.ref === newBot?.ref) {
				return newBot;
			} else return item;
		});

		// Проверка и обнуление secure_bot_name если бот был привязан
		const filteredNewBotList = newBotList.map((item) => {
			if (item.secure_bot_name === oldBot?.bot_name) {
				const postObj = {
					...item,
					secure_bot_name: null,
				};
				updateBot(item.ref, getFilteredBotToPost(postObj));

				return {
					...item,
					secure_bot_name: "Без бота",
				};
			} else return item;
		});

		// Обновление secureBotList
		const deletedName = oldBot?.bot_name || "";

		const oldSecureBotList = [];
		if (secureBotList) oldSecureBotList.push(...secureBotList);
		const newSecureBotList = oldSecureBotList.filter(
			(name) => name !== deletedName
		);
		newSecureBotList.splice(1, 0, name);
		setSecureBotList(newSecureBotList);

		// TODO: Обновление userList (по ref??? он не меняется)

		setChatbotList(filteredNewBotList);
		updateBot(selectedSidebarItem, getFilteredBotToPost(newBot));
	};

	// Переключение бота
	const handleSidebarSelect = (ref: string) => {
		if (!chatbotList) return;
		const selectedBot = chatbotList.filter((item) => item.ref === ref)[0];
		const secureBot = chatbotList.find(
			(item) => item.bot_name === selectedBot.secure_bot_name
		);

		const promises: Promise<{
			botType: "selectedBot" | "secureBot";
			chats: ChatListObject;
		}>[] = [];
		if (!selectedBot.chats || !userList?.find((item) => item.ref === ref)) {
			setIsChatsLoading(true);
			promises.push(
				getBotChats(ref).then((chats) => ({ botType: "selectedBot", chats }))
			);
		}
		if (secureBot && !secureBot?.chats) {
			setIsChatsLoading(true);
			promises.push(
				getBotChats(secureBot.ref).then((chats) => ({
					botType: "secureBot",
					chats,
				}))
			);
		}

		if (promises.length) {
			Promise.all(promises)
				.then((results) => {
					const newChatbotList = [...chatbotList];
					let newSelectedBotChats: ChatListObject | null = null;
					let newSecureBotChats: ChatListObject | null = null;

					results.forEach(({ botType, chats }) => {
						if (botType === "selectedBot") {
							newSelectedBotChats = chats;
						} else if (botType === "secureBot") {
							console.log("secureBot", chats);
							newSecureBotChats = chats;
						}
					});

					if (newSelectedBotChats) {
						const newChats: ChatObjectWithBlocked[] =
							(newSelectedBotChats as ChatListObject).chats.map((chat) => ({
								...chat,
								isBlocked: false,
								messages: [],
							})) || [];
						const newSelectedBot = { ...selectedBot, chats: newChats };

						const selectedBotIndex = newChatbotList.findIndex(
							(bot) => bot.ref === selectedBot.ref
						);
						if (selectedBotIndex !== -1) {
							newChatbotList[selectedBotIndex] = newSelectedBot;
						}

						const newUserList: UserListType[] = [];
						if (userList) {
							newUserList.push(...userList);
						}
						const newUserListObject = getLocalUserListObject(
							newSelectedBot,
							newSelectedBotChats
						);
						newUserList.push(newUserListObject);
						setUserList(newUserList);
					}

					if (newSecureBotChats && secureBot) {
						const secureBotCopy = { ...secureBot };
						const newSecureChats: ChatObjectWithBlocked[] = (
							newSecureBotChats as ChatListObject
						).chats.map((chat) => ({
							...chat,
							isBlocked: false,
							messages: [],
						}));
						secureBotCopy.chats = newSecureChats;

						const secureBotIndex = newChatbotList.findIndex(
							(bot) => bot.ref === secureBotCopy.ref
						);
						if (secureBotIndex !== -1) {
							newChatbotList[secureBotIndex] = secureBotCopy;
						}
					}

					setChatbotList([...newChatbotList]);
				})
				.finally(() => setIsChatsLoading(false));
		}

		setSelectedSidebarItem(ref);
	};

	// Удаление бота
	const handleBotDelete = (ref: string) => {
		if (!chatbotList) return;
		setIsChatbotListLoading(true);
		deleteBot(ref)
			.then(() => {
				const deletedBot = chatbotList.filter((item) => item.ref === ref)[0];
				const newBotList = chatbotList.filter((item) => item.ref !== ref);

				// Проверка и обнуление secure_bot_name если бот был привязан
				const filteredNewBotList = newBotList.map((item) => {
					if (item.secure_bot_name === deletedBot.bot_name) {
						const postObj = {
							...item,
							secure_bot_name: null,
						};
						updateBot(item.ref, getFilteredBotToPost(postObj));

						return {
							...item,
							secure_bot_name: "Без бота",
						};
					} else return item;
				});

				setChatbotList(filteredNewBotList);
				if (selectedSidebarItem === ref && newBotList.length) {
					setSelectedSidebarItem(newBotList[0].ref);
				}

				// Обновление secureBotList
				const deletedName = chatbotList.find(
					(item) => item.ref === ref
				)?.bot_name;
				const oldSecureBotList = [];
				if (secureBotList) oldSecureBotList.push(...secureBotList);
				const newSecureBotList = oldSecureBotList.filter(
					(name) => name !== deletedName
				);
				setSecureBotList(newSecureBotList);

				// Удаление списков пользователей
				if (userList) {
					const newUserList = [...userList];
					newUserList.filter((item) => item.ref !== ref);
					setUserList(newUserList);
				}
			})
			.finally(() => setIsChatbotListLoading(false));

		setDeleteDialogOpen(false);
		const timerId = setTimeout(() => {
			setDeletingInfo(defaultDeleteInfo);
		}, 300);
		return () => clearTimeout(timerId);
	};

	//================================================= UTILITY FUNCTIONS =================================================//

	// Обновление данных в chatbotList новым объектом
	const handleChangeChatbotList = (selectedItem: ChatbotWithChats) => {
		if (chatbotList?.find((item) => item.ref === selectedItem.ref)) {
			setChatbotList(
				chatbotList.map((item) =>
					item.ref === selectedItem.ref ? selectedItem : item
				)
			);
		}
	};

	// Изменение привязанного ассистента в таблице
	const handleTableAssistantChange = (newAssistantId: string) => {
		if (!selectedSidebarItem || !changingChatId) return;
		const selectedBot = chatbotList?.find(
			(item) => item.ref === selectedSidebarItem
		);

		let newBot = chatbotList?.find((item) => item.ref === selectedSidebarItem);
		if (!newBot || !newBot.chats) return;

		let newChat = newBot.chats?.find((chat) => chat.chat_id === changingChatId);
		if (!newChat || !newBot.chats) return;

		const selectedAssistantId = selectedBot?.asst_name;
		const sentAssistantId =
			newAssistantId === selectedAssistantId ? "def" : newAssistantId;

		newChat.asst_name = sentAssistantId;
		newBot.chats?.map((chat) =>
			chat.chat_id === changingChatId ? newChat : chat
		);
		handleChangeChatbotList(newBot);
		updateChatAssistant(selectedSidebarItem, changingChatId, {
			asst_name: sentAssistantId,
		}).then(console.log);
		console.log(newBot);
	};

	// Получение ID ассистента, которого мы меняем в таблице для передачи в диалог селект
	const getSelectedTableAssistantId = (): string => {
		if (!selectedSidebarItem || !changingChatId) return "";
		const localSelectedBot = chatbotList?.find(
			(item) => item.ref === selectedSidebarItem
		);
		const localSelectedAssistant = localSelectedBot?.chats?.find(
			(chat) => chat.chat_id === changingChatId
		)?.asst_name;
		if (!localSelectedBot || !localSelectedAssistant) return "";
		if (localSelectedAssistant === "def")
			return localSelectedBot.asst_name || "";
		return localSelectedAssistant;
	};

	// Получение нового объекта userList для нового бота (инициализация, переключение бота)
	const getLocalUserListObject = (
		selectedBot: ChatbotWithChats,
		chats: ChatListObject
	): UserListType => {
		const localUserOptions = [...chats.chats].map((chat) => ({
			id: chat.chat_id,
			name: chat.chat_name,
		}));
		localUserOptions.unshift({
			id: "emptyId",
			name: "Без пользователя",
		});

		const localSelectedWhitelistUsers: SelectedUserListType[] =
			selectedBot.white_list?.map((whitelistId) => ({
				selectId: generateStringId(),
				id: whitelistId,
				name:
					localUserOptions.find((user) => user.id === whitelistId)?.name || "",
			})) || [
				{
					selectId: generateStringId(),
					id: "emptyId",
					name: "Без пользователя",
				},
			];

		const localSelectedBlacklistUsers: SelectedUserListType[] =
			selectedBot.black_list?.map((blacklistId) => ({
				selectId: generateStringId(),
				id: blacklistId,
				name:
					localUserOptions.find((user) => user.id === blacklistId)?.name || "",
			})) || [
				{
					selectId: generateStringId(),
					id: "emptyId",
					name: "Без пользователя",
				},
			];

		return {
			ref: selectedBot.ref,
			userOptions: localUserOptions,
			selectedBlacklistUsers: localSelectedBlacklistUsers,
			selectedWhitelistUsers: localSelectedWhitelistUsers,
		};
	};

	// Функция для работы с userList (добавление, изменение, удаление пользователей в списках)
	const handleChangeUserlist = (
		functionType: "add" | "change" | "delete",
		ref: string,
		listType: "white_list" | "black_list",
		updatedUser?: SelectedUserListType
	): UserListType | undefined => {
		if (!userList) return;
		const selectedBotInfo = userList.find((userInfo) => userInfo.ref === ref);
		if (!selectedBotInfo) return;
		let newBotInfo = { ...selectedBotInfo };
		const localListType =
			listType === "white_list"
				? "selectedWhitelistUsers"
				: "selectedBlacklistUsers";

		switch (functionType) {
			case "add":
				newBotInfo[localListType].push({
					selectId: generateStringId(),
					id: "emptyId",
					name: "Без пользователя",
				});

				break;

			case "delete":
				if (!updatedUser) return;
				newBotInfo[localListType] = newBotInfo[localListType].filter(
					(user) => user.selectId !== updatedUser.selectId
				);

				break;

			case "change":
				if (!updatedUser) return;
				let newUserInfo = newBotInfo[localListType].find(
					(user) => user.selectId === updatedUser.selectId
				);
				if (!newUserInfo) return;
				newUserInfo.id = updatedUser.id;
				newUserInfo.name = updatedUser.name;

				const newListUsers = newBotInfo[localListType].map((user) => {
					if (newUserInfo && user.selectId === newUserInfo.selectId) {
						return newUserInfo;
					}
					return user;
				});
				newBotInfo[localListType] = newListUsers;

				break;
		}

		const newUserList = userList.map((user) => {
			if (user.ref === ref) {
				return newBotInfo;
			}
			return user;
		});
		setUserList(newUserList);
		return newBotInfo;
	};

	// Открытие диалогового окна удаления бота
	const handleDeleteDialogOpen = (id: string, name: string) => {
		setDeletingInfo({ id, name });
		setDeleteDialogOpen(true);
	};

	// Открытие диалогового окна изменения API бота
	const handleChangeBotDialogOpen = (oldToken: string) => {
		// setToken(oldToken);
		setChangeBotDialogOpen(true);
	};

	// Закрытие диалоговых окон
	const handleDialogClose = () => {
		setDeleteDialogOpen(false);
		setNewBotDialogOpen(false);
		setChangeBotDialogOpen(false);
		setChangeAssistantDialogOpen(false);
		const timerId = setTimeout(() => {
			setDeletingInfo(defaultDeleteInfo);
			setToken("");
			localResetTokenErrors();
			setChangingChatId(null);
		}, 300);

		return () => clearTimeout(timerId);
	};

	// Импорт функций для работы с ошибками токена
	const localSetIsTokenWrong = (
		isTokenWrong: boolean,
		newTokenErrors?: TokenError[]
	) =>
		setIsTokenWrong(
			newTokenErrors || tokenErrors,
			setTokenErrors,
			isTokenWrong
		);
	const localSetIsTokenExisting = (
		isTokenExisting: boolean,
		newTokenErrors?: TokenError[]
	) =>
		setIsTokenExisting(
			newTokenErrors || tokenErrors,
			setTokenErrors,
			isTokenExisting
		);
	const localResetTokenErrors = () =>
		resetTokenErrors(tokenErrors, setTokenErrors);

	// // Testing
	// useEffect(() => {
	//   console.log(chatbotList, userList);
	// }, [chatbotList, userList]);

	return (
		<div className={styles["page-layout"]}>
			<AppSidebar
				menuItems={
					chatbotList?.map((item) => ({
						id: item.ref,
						name: item.bot_name,
					})) || []
				}
				selectedId={selectedSidebarItem}
				onSelect={handleSidebarSelect}
				addButton
				onAdd={() => setNewBotDialogOpen(true)}
				deleteButton
				handleDialogOpen={handleDeleteDialogOpen}
				isLoading={isChatbotListLoading}
			/>
			{chatbotList &&
				selectedSidebarItem &&
				secureBotList &&
				chatbotList.length !== 0 && (
					<ResizableWrapper
						leftBlock={
							<ChatbotMain
								chatbotList={chatbotList}
								setChatbotList={setChatbotList}
								selectedBot={
									chatbotList.filter(
										(item) => item.ref === selectedSidebarItem
									)[0]
								}
								handleChangeChatbotList={handleChangeChatbotList}
								handleChangeApi={handleChangeBotDialogOpen}
								assistantList={assistantList}
								secureBotList={secureBotList}
								selectedUserList={
									userList?.find((user) => user.ref === selectedSidebarItem) ||
									null
								}
								handleChangeUserlist={handleChangeUserlist}
								setChangeAssistantDialogOpen={setChangeAssistantDialogOpen}
								setChangingChatId={setChangingChatId}
								isChatsLoading={isChatsLoading}
							/>
						}
						rightBlock={
							<ChatbotDialogs
								selectedBot={
									chatbotList.filter(
										(item) => item.ref === selectedSidebarItem
									)[0]
								}
								selectedUserList={
									userList?.find((user) => user.ref === selectedSidebarItem) ||
									null
								}
								handleChangeChatbotList={handleChangeChatbotList}
								isChatsLoading={isChatsLoading}
							/>
						}
						isCollapsible={false}
						leftBlockSize={55}
						rightBlockSize={45}
						leftBlockMinSize={30}
					/>
				)}
			<AppDeleteDialog
				dialogOpen={deleteDialogOpen}
				deletingInfo={deletingInfo}
				handleDialogClose={handleDialogClose}
				onDelete={handleBotDelete}
			/>
			<AppNewBotDialog
				dialogOpen={newBotDialogOpen}
				token={token}
				setToken={setToken}
				handleDialogClose={handleDialogClose}
				handleClick={() => handleTokenVerification("create")}
				tokenErrors={tokenErrors}
				isTokenCheckLoading={isTokenCheckLoading}
			/>
			<AppChangeBotDialog
				dialogOpen={changeBotDialogOpen}
				token={token}
				setToken={setToken}
				handleDialogClose={handleDialogClose}
				handleClick={() => handleTokenVerification("edit")}
				tokenErrors={tokenErrors}
				isTokenCheckLoading={isTokenCheckLoading}
			/>
			<AppChangeTableAssistantDialog
				dialogOpen={changeAssistantDialogOpen}
				assistants={assistantList || []}
				selectedAssistantId={getSelectedTableAssistantId()}
				handleDialogClose={handleDialogClose}
				handleClick={handleTableAssistantChange}
			/>
		</div>
	);
};
